import { Stack, DefaultButton, PrimaryButton, getTheme, Dropdown, Label, IconButton, Spinner, SpinnerSize, TooltipHost, Checkbox, MessageBar, MessageBarType } from "@fluentui/react"
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { IMultiSectionUnitConfiguration } from "../../../../Models/IUnitConfiguration";
import { useMsal } from "@azure/msal-react";
import { ApiService } from "../../../../Services/ApiService";
import { generalStackStyles, informationLabelStyle } from "../../../../Models/StackStyling";
import { ICalculationGroup, ICalculationInput, ICalculationOutput, IUnitPropertySpecification } from "../../../../Models/IUnitPropertySpecification";
import { Badge, Input } from "@fluentui/react-components";
import { setTemporaryState } from "../../../../Services/Global";
import { MultiSectionFlowchart } from "../../../Atom/MultiSectionVisualization/MultiSectionFlowchart";

type InputOutputFieldType = "input" | "output";

/**
 * Unit property specification editor component to manage the unit property specifications
 *
 * @return {*} 
 */
export const UnitPropertySpecificationEditor: React.FC<{}> = (props) => {
    let ctx = useMsal();
    const apiService = useMemo(() => new ApiService(ctx), [ctx]);

    let navigate = useNavigate();
    let params = useParams();
    let theme = getTheme();

    const [unitConfig] = useState<IMultiSectionUnitConfiguration>({
        industry: params.i,
        subIndustry: params.si,
        application: params.a,
        subApplication: params.sa,
        processStage: params.ps,
        productLine: params.pl,
        alfaLavalPosition: params.alp,
        sectionCount: Number.parseInt(params.sc as string)
    });
    const [sectionConfigs, setSectionConfigs] = useState<IMultiSectionUnitConfiguration[]>([]);
    const [pickableProperties, setPickableProperties] = useState<string[]>([]);
    const [selectedProperty, setSelectedProperty] = useState<string>();
    const [selectedUserInput, setSelectedUserInput] = useState<string>();
    const [copyMessage, setCopyMessage] = useState<string>("");
    const [specifications, setSpecifications] = useState<ICalculationGroup[]>([]);
    const [specId, setSpecId] = useState<string>();
    const [isSaving, setIsSaving] = useState(false);
    const [isSavingUserInputs, setIsSavingUserInputs] = useState(false);
    const [saveMessage, setSaveMessage] = useState<string>();
    const [saveMessageUserInputs, setSaveMessageUserInputs] = useState<string>();
    const [showAllProperties, setShowAllProperties] = useState<boolean>(false);

    const [inputValues, setInputValues] = useState<ICalculationInput[]>([]);
    const [outputValues, setOutputValues] = useState<ICalculationOutput[]>([]);

    const [userInputs, setUserInputs] = useState<string[]>();

    useEffect(() => {
        async function getFullUnitConfiguration() {
            let configs: IMultiSectionUnitConfiguration[] = [];
            if (unitConfig.sectionCount === 1) {
                if (!state.unitConfiguration) {
                    const response = await apiService.getAsync("settings/unitconfiguration", unitConfig);
                    const data = await response.json();
                    configs = [data];
                }
                else {
                    configs = [state.unitConfiguration];
                }
            }
            else {
                const response = await apiService.getAsync("settings/unitconfigurations/sections", unitConfig);
                const data = await response.json();
                configs = data;
            }

            let allPickables: string[] = ["UnitOperationTargetValue"];
            configs.forEach((c, index) => {
                c.inputs?.forEach((i) => {
                    allPickables.push(`${i}Sec${index + 1}`);
                });

                c.outputs?.forEach((o) => {
                    allPickables.push(`${o}Sec${index + 1}`);
                });
            });
            setPickableProperties(allPickables);
        }


        /**
         * Get the unit property specification from the API and set the state with the result
         *
         * @return {*} 
         */
        async function getUnitPropertySpecification() {
            const response = await apiService.getAsync("settings/unitpropertyspecifications", unitConfig);
            if (!response.ok) {
                if (response.status === 404) {
                    return;
                }

                throw new Error(`${response.status}`);
            }

            try {
                const result: IUnitPropertySpecification = await response.json();

                if (result) {
                    setSpecifications(result.calculations);
                    setSpecId(result.id)
                    setUserInputs(result.userInputProperties ?? []);
                }
            }
            catch {
                console.log('error while parsing json of unit property specification');
            }

        }

        getFullUnitConfiguration();
        getUnitPropertySpecification();
    }, [])


    /**
     * Add a new calculation group to the specifications state array if a property is selected from the dropdown
     *
     * @return {*} 
     */
    const addNewCalculationGroup = () => {
        if (!selectedProperty) return;

        let currentSpecifications = [...specifications];
        let target = currentSpecifications.find((g) => g.name === selectedProperty);
        if (!target) {
            target = {
                name: selectedProperty,
                inputs: [],
                outputs: []
            };
            currentSpecifications.push(target);
        }

        setSpecifications([...currentSpecifications]);
    }

    const handleDropdownChange = (index: number, value: string) => {
        setSelectedProperty(value);
    }


    /**
     * Handle adding a new field to the calculation group based on the input type (input or output) and the key of the group to add the field to the correct group in the state
     *
     * @param {ICalculationGroup} spec
     * @param {number} key
     * @param {InputOutputFieldType} inputType
     */
    const handleAddField = (spec: ICalculationGroup, key: number, inputType: InputOutputFieldType) => {
        let current = specifications;
        let index = current.findIndex(x => x.name === spec.name);
        if (inputType === "input") {
            current[index].inputs?.push({
                data: "",
                unit: "",
                name: "",
                key: current[index].inputs.length,
                groupRef: spec.name
            });
        }
        else {
            current[index].outputs?.push({
                calculation: "",
                unit: "",
                name: "",
                key: 0, // only one allowed
                groupRef: spec.name
            });
        }

        setSpecifications([...current]);
    }


    /**
     * Handle copying the content to the clipboard and set a temporary state to show the user that the content was copied successfully 
     *
     * @param {string} content
     */
    function handleCopy(content: string) {
        navigator.clipboard.writeText(content);
        setTemporaryState(content, setCopyMessage);
    }


    /**
     * Handle the input data and update the state with the new data for the input field in the calculation group based on the index, group name and property to update 
     *
     * @param {string} data
     * @param {number} index
     * @param {string} groupName
     * @param {string} property
     * @return {*}  {void}
     */
    function handleInput(data: string, index: number, groupName: string, property: string): void {
        let current = specifications.find((s) => s.name === groupName)?.inputs;
        if (current === undefined) return;

        let target = current.find(x => x.groupRef === groupName && x.key === index);
        console.log('target', target, index, groupName, current);
        if (target === undefined) {
            target = {
                data: data,
                key: index,
                unit: "",
                groupRef: groupName,
                name: ""
            }

            current.push(target);
        }

        target = { ...target, [property]: data };

        current[current.findIndex(x => x.groupRef === groupName && x.key === index)!] = target;

        setInputValues([...current]);
        let currentSpec = specifications.find((s) => s.name === groupName)!;
        currentSpec.inputs = current;
        let updatedSpecs = specifications;
        updatedSpecs[specifications.findIndex(x => x.name === groupName)!] = currentSpec;
        setSpecifications([...updatedSpecs]);
    }

    function handleOutput(data: string, index: number, groupName: string, property: string): void {
        let current = specifications.find((s) => s.name === groupName)?.outputs;
        if (current === undefined) return;

        let target = current.find(x => x.groupRef === groupName && x.key === index);
        console.log('target', target, index, groupName, current);

        if (target === undefined) {
            target = {
                calculation: "",
                key: index,
                unit: "",
                groupRef: groupName,
                name: ""
            }

            current.push(target);
        }

        target = { ...target, [property]: data };
        current[current.findIndex(x => x.groupRef === groupName && x.key === index)!] = target;

        setOutputValues([...current]);
        let currentSpec = specifications.find((s) => s.name === groupName)!;
        currentSpec.outputs = current;
        let updatedSpecs = specifications;
        updatedSpecs[specifications.findIndex(x => x.name === groupName)!] = currentSpec;
        setSpecifications([...updatedSpecs]);
    }


    /**
     * Handle adding a new field to the calculation group based on the input type (input or output) and the key of the group to add the field to the correct group in the state 
     *
     */
    const handleSubmit = async () => {
        setIsSaving(true);
        let payload: any = {
            ...unitConfig,
            calculations: specifications,
            userInputProperties: userInputs
        }
        let response;
        if (specId) {
            payload.id = specId;
            response = await apiService.putAsync(payload, `settings/unitPropertySpecifications/${specId}`);
        }
        else {
            response = await apiService.postAsync(payload, "settings/unitPropertySpecifications");
        }

        if (!response.ok) {
            setIsSaving(false);
            setTemporaryState("Failed to save.", setSaveMessage);
            const message = await response.text();
            throw new Error(`${message} (${response.status})`);
        }

        const result: IUnitPropertySpecification = await response.json();
        setSpecifications(result.calculations);
        setSpecId(result.id);
        setTemporaryState("✅ Saved", setSaveMessage);
        setIsSaving(false);
    }


    /**
     * To remove a field from the calculation group based on the index, group name and type of field to remove
     *
     * @param {number} index
     * @param {string} group
     * @param {InputOutputFieldType} type
     * @return {*} 
     */
    const handleRemoveField = (index: number, group: string, type: InputOutputFieldType) => {
        let target = specifications.find(x => x.name === group);
        if (!target) return;

        if (type === "input") {
            target?.inputs.splice(index, 1);
        }
        else {
            target?.outputs.splice(index, 1);
        }

        let updatedSpecs = specifications;
        updatedSpecs[specifications.findIndex(x => x.name === group)!] = target;
        setSpecifications([...updatedSpecs]);
    }


    /**
     * Handle removing a specification group based on the index of the group to remove from the state array of specifications 
     *
     * @param {number} index
     * @return {*} 
     */
    const handleRemoveSpecification = (index: number) => {
        let target = specifications[index];
        if (!target) return;

        let updatedSpecs = specifications;
        updatedSpecs.splice(index, 1);
        setSpecifications([...updatedSpecs]);
    }


    /**
     * Add a user input to the state array of user inputs if it does not already exist
     *
     * @param {string} value
     */
    const addUserInput = (value: string) => {
        console.log('adding user input', value)
        let currentInputs = userInputs;
        if (!currentInputs) {
            currentInputs = [];
        }
        if (currentInputs.findIndex(x => x === value) === -1) {
            currentInputs.push(value);
        }

        setUserInputs([...currentInputs]);
    }


    /**
     * Remove a user input from the state array of user inputs if it exists
     *
     * @param {string} value
     */
    const removeUserInput = (value: string) => {
        let currentInputs = userInputs;
        if (!currentInputs) {
            currentInputs = [];
        }
        if (currentInputs.findIndex(x => x === value) !== -1) {
            currentInputs.splice(currentInputs.findIndex(x => x === value), 1);
        }

        setUserInputs([...currentInputs]);
    }
    const state: any = useLocation().state;
    const handleNavigation = () => {
        if (state && state.unitConfiguration) {
            navigate('/admin/UnitConfigurationManagement/unitconfigurationeditor', { state: { unitConfiguration: state.unitConfiguration } });
        }
        else {
            navigate(-1);
        }
    };

    return (
        <Stack>
            <Stack horizontal style={{ padding: 8 }} >
                <Stack.Item grow>
                    <DefaultButton iconProps={{ iconName: "Back" }} text="Back" onClick={() => handleNavigation()} />
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal>
                        <Stack.Item style={{ marginTop: 5, marginRight: 8 }}>
                            {
                                isSaving && <Spinner labelPosition="left" label="Saving..." size={SpinnerSize.small} />
                            }
                            {
                                saveMessage &&
                                <span>{saveMessage}</span>
                            }
                        </Stack.Item>
                        <Stack.Item>
                            <PrimaryButton disabled={isSaving} iconProps={{ iconName: "Save" }} onClick={() => handleSubmit()} text="Save all information" />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>

            <Stack horizontalAlign="center">
                <Stack.Item grow style={{ padding: 5, marginLeft: 10, marginBottom: 10 }}>
                    <h3>Unit property specification</h3>

                </Stack.Item>
                <Stack.Item>
                    <MultiSectionFlowchart
                        sectionCount={unitConfig.sectionCount ?? 1}
                        unitInputConfiguration={unitConfig}
                        scaling={0.9}
                        selectableProperties={[]}
                    />
                </Stack.Item>
            </Stack>

            <Stack horizontal style={{ padding: "5px 20px" }}>
                <Stack.Item grow>
                    <h3>Assign user inputs</h3>
                </Stack.Item>
            </Stack>
            <Stack styles={generalStackStyles(theme)}>
                <Stack.Item>
                    <Label>Assigned user inputs</Label>
                    <Stack horizontal wrap tokens={{ childrenGap: 8 }}>
                        {
                            userInputs?.map((x, index) => {
                                return <Stack.Item onClick={() => removeUserInput(x)}>
                                    <Badge style={{ padding: 15 }} appearance="filled" color={x.toLowerCase().includes('hot') ? "danger" : "brand"} key={`userinput-${index}`}>{x}</Badge>
                                </Stack.Item>
                            })
                        }
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Label>Available properties</Label>
                    <Stack horizontal wrap tokens={{ childrenGap: 8 }}>
                        {
                            pickableProperties?.filter(x => !userInputs?.includes(x)).map((x, index) => {
                                return <Stack.Item onClick={() => addUserInput(x)}>
                                    <Badge style={{ padding: 15 }} appearance="tint" color={x.toLowerCase().includes('hot') ? "danger" : "brand"} key={`selectable-user-input-${index}`}>{x}</Badge>
                                </Stack.Item>
                            })
                        }
                    </Stack>
                </Stack.Item>
            </Stack>

            <Stack horizontal style={{ padding: "5px 20px" }}>
                <Stack.Item grow style={{ marginBottom: 10 }}>
                    <h3>Specifications</h3>
                </Stack.Item>
            </Stack>
            <Stack styles={generalStackStyles(theme)}>
                <Stack.Item grow>
                    <Stack horizontal>
                        <Stack.Item>
                            <Dropdown label="Available properties" style={{ width: 300 }}
                                onChange={(e, item) => handleDropdownChange(0, item?.key as string)}
                                options={showAllProperties ?
                                    pickableProperties?.map((prop) => { return { key: prop, text: prop } }) ?? []
                                    :
                                    pickableProperties?.filter(x => userInputs?.includes(x)).map((prop) => { return { key: prop, text: prop } }) ?? []}
                                selectedKey={selectedProperty ?? ""} />
                        </Stack.Item>
                        <Stack.Item style={{ marginTop: 35, marginLeft: 10 }}>
                            <Checkbox checked={showAllProperties} label="Show all properties" onChange={() => setShowAllProperties(!showAllProperties)} />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item style={{ marginTop: 8 }}>
                    <PrimaryButton iconProps={{ iconName: "Add" }} onClick={() => addNewCalculationGroup()} text="Add new specification" />
                </Stack.Item>
            </Stack>
            <Stack>
                <Stack.Item style={{ padding: 8 }}>
                    <MessageBar messageBarType={MessageBarType.warning}>If a group is dependent on an output from an upstream group, they need to be added subsequentially.</MessageBar>
                </Stack.Item>
            </Stack>
            <Stack.Item>

                {
                    specifications && specifications.map((spec, index) => {
                        return <Stack key={`spec-${index}`} styles={generalStackStyles(theme)}>
                            <Stack horizontal>
                                <Stack.Item grow>
                                    <Label styles={
                                        informationLabelStyle(theme, spec.name.toLowerCase().includes("hot")
                                            ?
                                            theme.palette.redDark
                                            :
                                            theme.palette.themePrimary)}
                                        style={{ textAlign: "center", marginBottom: 10 }}>
                                        {spec.name}
                                    </Label>
                                </Stack.Item>
                                <Stack.Item style={{ marginLeft: 10 }}>
                                    <TooltipHost content={`Delete group`}>
                                        <IconButton style={{ color: theme.palette.redDark }} iconProps={{ iconName: "Delete" }} onClick={() => handleRemoveSpecification(index)} />
                                    </TooltipHost>
                                </Stack.Item>
                            </Stack>
                            <Stack styles={generalStackStyles(theme)} style={{ borderLeft: `solid ${theme.palette.themeSecondary} 5px`, paddingLeft: 10, marginTop: 10 }}>
                                {
                                    spec.inputs?.map((input, ind) => {
                                        return <Stack horizontal key={`group-${index}-input-${ind}`} tokens={{ childrenGap: 10 }}>
                                            <Stack.Item>
                                                <Dropdown label="Pick from properties" style={{ width: 300 }}
                                                    onChange={(e, item) => handleInput(item?.key as string, ind, spec.name, "name")}
                                                    options={pickableProperties?.map((prop) => { return { key: prop, text: prop } }) ?? []}
                                                    selectedKey={input.name ?? ""} />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Label>Field name</Label>
                                                <Input onChange={(e) => handleInput(e.currentTarget.value, ind, spec.name, "name")} value={input.name} />
                                            </Stack.Item>
                                            {/* <Stack.Item>
                                                <Label>Unit</Label>
                                                <Input onChange={(e) => handleInput(e.currentTarget.value, ind, spec.name, "unit")} value={input.unit} />
                                            </Stack.Item> */}
                                            <Stack.Item grow>
                                                <Label>Field reference</Label>
                                                <Stack horizontal>
                                                    <Stack.Item style={{ marginTop: 5 }}>
                                                        <code>{`group_${index}_input_${ind}`}</code>
                                                    </Stack.Item>
                                                    <TooltipHost content={"Copy"}>
                                                        <IconButton style={{ marginLeft: 8 }} iconProps={{ iconName: "Copy" }} onClick={() => handleCopy(`group_${index}_input_${ind}`)} />
                                                    </TooltipHost>
                                                    {
                                                        copyMessage === `group_${index}_input_${ind}` &&
                                                        <span style={{ color: theme.palette.green, marginLeft: 8, marginTop: 5 }}>✅ Copied!</span>
                                                    }
                                                </Stack>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Label>Delete</Label>
                                                <TooltipHost content={`Delete input ${input.name ?? ""}`}>
                                                    <IconButton style={{ color: theme.palette.redDark }} iconProps={{ iconName: "Delete" }} onClick={() => handleRemoveField(ind, spec.name, "input")} />
                                                </TooltipHost>
                                            </Stack.Item>
                                        </Stack>
                                    })
                                }
                                <Stack.Item style={{ marginTop: 10 }}>
                                    <DefaultButton onClick={() => handleAddField(spec, index, "input")} text="New input" iconProps={{ iconName: "Add" }} />
                                </Stack.Item>
                            </Stack>

                            <Stack styles={generalStackStyles(theme)} style={{ borderLeft: `solid ${theme.palette.themeSecondary} 5px`, paddingLeft: 10, marginTop: 10 }}>
                                {
                                    spec.outputs?.map((output, ind) => {
                                        return <Stack horizontal key={`group-${index}-output-${ind}`} tokens={{ childrenGap: 10 }}>
                                            <Stack.Item grow>
                                                <Label>Calculation ({spec.name})</Label>
                                                <Input onChange={(e) => handleOutput(e.currentTarget.value, 0, spec.name, "calculation")} style={{ width: "100%" }} value={output.calculation} />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Label>Unit</Label>
                                                <Input onChange={(e) => handleOutput(e.currentTarget.value, 0, spec.name, "unit")} value={output.unit} />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Label>Field reference</Label>
                                                <Stack horizontal>
                                                    <Stack.Item style={{ marginTop: 5 }}>
                                                        <code>{`group_${index}_output_${ind}`}</code>
                                                    </Stack.Item>
                                                    <TooltipHost content={"Copy"}>
                                                        <IconButton style={{ marginLeft: 8 }} iconProps={{ iconName: "Copy" }} onClick={() => handleCopy(`group_${index}_output_${ind}`)} />
                                                    </TooltipHost>
                                                    {
                                                        copyMessage === `group_${index}_output_${ind}` &&
                                                        <span style={{ color: theme.palette.green, marginLeft: 8, marginTop: 5 }}>✅ Copied!</span>
                                                    }
                                                </Stack>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Label>Delete</Label>
                                                <TooltipHost content={`Delete output ${output.name ?? ""}`}>
                                                    <IconButton style={{ color: theme.palette.redDark }} iconProps={{ iconName: "Delete" }} onClick={() => handleRemoveField(ind, spec.name, "output")} />
                                                </TooltipHost>
                                            </Stack.Item>
                                        </Stack>
                                    })
                                }
                                {
                                    spec.outputs?.length < 1 &&
                                    <Stack.Item style={{ marginTop: 10 }}>
                                        <DefaultButton onClick={() => handleAddField(spec, index, "output")} text="New output" iconProps={{ iconName: "Add" }} />
                                    </Stack.Item>
                                }
                            </Stack>
                        </Stack>
                    })
                }

            </Stack.Item>
        </Stack>
    )
}