import { Stack, Label, TextField, ComboBox, PrimaryButton, useTheme, IComboBoxOption, SelectableOptionMenuItemType, DefaultButton, Spinner, Dropdown, SpinnerSize, Checkbox, inputProperties } from "@fluentui/react";
import { deCapitalizeFirstLetter, getPreferredUnits, setTemporaryState } from "../../../../Services/Global";
import { MultiSectionUnitConfiguration } from "../MultiSectionUnitConfiguration";
import { useEffect, useMemo, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { ApiService } from "../../../../Services/ApiService";
import { IMultiSectionUnitConfiguration, IUnitBaseConfiguration } from "../../../../Models/IUnitConfiguration";
import { IPHEPlateType } from "../../../../Models/IPHEPlateType";
import { ColdConditionalFields, HotConditionalFields } from "../../../../Models/Enums";
import "./UnitConfigurationEditor.scss"
import { Constants, PropertyConstants } from "../../../../Models/Constants";
import { useLocation, useNavigate } from "react-router-dom";
import { generalStackStyles } from "../../../../Models/StackStyling";
import { MultiSectionFlowchart } from "../../../Atom/MultiSectionVisualization/MultiSectionFlowchart";

/**
 * UnitConfigurationEditor component
 *
 * @param {{}} props
 * @return {*} 
 */
export const UnitConfigurationEditor: React.FC<{}> = (props) => {
    const ctx = useMsal();
    const apiService = useMemo(() => new ApiService(ctx), [ctx]);
    let navigate = useNavigate();
    let theme = useTheme();

    let state: any = useLocation().state;

    const isEdit = state !== undefined && state !== null;

    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [amountOfSections, setAmountOfSections] = useState(1);
    const [pheTypesOptions, setPHETypeOptions] = useState<IComboBoxOption[]>();
    const [inputOutputOptions, setInputOutputOptions] = useState<IComboBoxOption[]>();
    const [unitConfig, setUnitConfig] = useState<IUnitBaseConfiguration>();
    const [section, setSection] = useState<IMultiSectionUnitConfiguration>({});
    const [sectionCollection, setSectionCollection] = useState<IMultiSectionUnitConfiguration[]>([{ sectionNumber: 1 }]);
    const [saveMessage, setSaveMessage] = useState<string>();
    const [activeSection, setActiveSection] = useState<number>(-1);
    const [assignOperationTargetValue, setAssignOperationValue] = useState<boolean>(false);
    const [propSpecId, setPropSpecId] = useState<string>();
    const [isNewCreated, setIsNewCreated] = useState<boolean>(false);
    const [userInputs, setUserInputs] = useState<string[]>();
    const [originalBasicDetails, setOriginalBasicDetails] = useState<IUnitBaseConfiguration>();

    const [flowchartSelectableProperties, setFlowchartSelectableProperties] = useState<string[]>([]);

    
    /**
     * Get sections async function to get the sections for the current unit configuration
     *
     */
    async function getSectionsAsync() {
        setIsLoading(true);
        await setPHETypeComboBoxOptions();
        setConditionalOptions();
        if (isEdit) {
            //after submitting the values retained are older as we are resetting the state here, so added this condition
            if (unitConfig === undefined) {
                setUnitConfig(state.unitConfiguration);
            }
            const unitConfiguration = unitConfig == undefined ? state.unitConfiguration : unitConfig;
            setOriginalBasicDetails(unitConfiguration);

            const response = await apiService.getAsync(`settings/unitconfigurations/sections`, unitConfiguration);
            if (!response.ok) {
                throw new Error("Couldn't get sections for current unit configuration.");
            }

            const data: IMultiSectionUnitConfiguration[] = await response.json();
            const sortedData = data.sort((a, b) => b.sectionNumber! - a.sectionNumber!)
            setSectionCollection(sortedData);
            setAmountOfSections(sortedData.length);
            setSection(sortedData[0]);

            let pickableProperties: string[] = []
            for (let i = 0; i < sortedData.length; i++) {
                let current = sortedData[i];
                if (current.inputs !== undefined) {
                    current.inputs.forEach(x => {
                        pickableProperties.push(`${x}Sec${current.sectionNumber}`)
                    })
                }
                if (current.outputs !== undefined) {
                    current.outputs.forEach(x => {
                        pickableProperties.push(`${x}Sec${current.sectionNumber}`)
                    })
                }
            }

            setFlowchartSelectableProperties(pickableProperties);

            let propData: any = {};

            const propResponse = await apiService.getAsync(`settings/unitPropertySpecifications`, unitConfiguration);
            try {
                propData = await propResponse.json();
            } catch (error) {

                console.log("Unit configuration do not have prop specification");
            }

            if (!propResponse.ok) {
                setIsLoading(false);
                throw new Error("Couldn't get unit property specifications for current unit configuration.");
            }
            setPropSpecId(propData.id);
            setUserInputs(propData.userInputProperties ?? []);

            if (propData.userInputProperties !== undefined && propData.userInputProperties.length > 0) {
                if (propData.userInputProperties.findIndex((x: any) => x === "UnitOperationTargetValue") > -1) {
                    setAssignOperationValue(true);
                }
            }



        }

        setIsLoading(false);
    }

    useEffect(() => {
        getSectionsAsync();
    }, [])

    
    /**
     * Handle combo box change function to handle the change of the combo box values for the inputs, outputs and PHE types
     *
     * @param {*} e
     * @param {string} property
     * @return {*} 
     */
    const handleComboBoxChange = (e: any, property: string) => {
        if (property === PropertyConstants.Inputs) {
            let currentInputs = section.inputs ?? [];
            if (e.selected === true) {
                currentInputs.push(e.key);
                setSection({ ...section, inputs: currentInputs });
            }
            else {
                let index = currentInputs.indexOf(e.key);
                currentInputs.splice(index, 1);
                setSection({ ...section, inputs: currentInputs });
            }
        }
        if (property === PropertyConstants.Outputs) {
            let currentOutputs = section.outputs ?? [];
            if (e.selected === true) {
                currentOutputs.push(e.key);
                setSection({ ...section, outputs: currentOutputs });
            }
            else {
                let index = currentOutputs.indexOf(e.key);
                currentOutputs.splice(index, 1);
                setSection({ ...section, outputs: currentOutputs });
            }
        }
        if (property === PropertyConstants.PHEType) {
            let currentPHETypes = unitConfig?.pheTypes ?? [];
            if (e.selected === true) {
                if (currentPHETypes.includes(e.key)) return;
                currentPHETypes.push(e.key);
                setUnitConfig({ ...unitConfig, pheTypes: currentPHETypes });
            }
            else {
                let index = currentPHETypes.indexOf(e.key);
                currentPHETypes.splice(index, 1);
                setUnitConfig({ ...unitConfig, pheTypes: currentPHETypes });
            }

            let allSections = [...sectionCollection];
            for (let i = 0; i < sectionCollection.length; i++) {
                let currentSection = sectionCollection[i];
                currentSection.pheTypes = currentPHETypes;
                allSections[i] = currentSection;
            }

            setSectionCollection(allSections);
        }
    }

    const setSectionActive = (index: number) => {
        setActiveSection(index);
    }

    
    /**
     * Set PHE type combo box options function to set the options for the PHE type combo box
     *
     */
    async function setPHETypeComboBoxOptions() {
        let options: IComboBoxOption[] = [];
        let header1: IComboBoxOption = { key: "Header1", text: "PHE Types", itemType: SelectableOptionMenuItemType.Header };
        options.push(header1);
        const response = await apiService.getAsync("settings/pheplatetypes");
        const data = await response.json();

        const pheData: IPHEPlateType[] = data;
        Object.values(pheData).filter(k => isNaN(Number(k))).map(r => r as IPHEPlateType).forEach(c => {
            let field: IComboBoxOption = {
                key: c.key,
                text: c.text
            }
            options.push(field)
        });

        setPHETypeOptions(options);
    }

    
    /**
     * Set conditional options function to set the options for the conditional fields in the combo box for the inputs and outputs
     *
     */
    function setConditionalOptions() {
        let options: IComboBoxOption[] = [];
        let header1: IComboBoxOption = { key: "Header1", text: "Cold Media", itemType: SelectableOptionMenuItemType.Header };
        options.push(header1);
        Object.values(ColdConditionalFields).filter(k => isNaN(Number(k))).map(r => r as string).forEach(c => {
            let field: IComboBoxOption = {
                key: c,
                text: c
            }
            options.push(field)
        });
        let header2: IComboBoxOption = { key: "Header2", text: "Hot Media", itemType: SelectableOptionMenuItemType.Header };
        options.push(header2);
        Object.values(HotConditionalFields).filter(k => isNaN(Number(k))).map(r => r as string).forEach(c => {
            let field: IComboBoxOption = {
                key: c,
                text: c
            }
            options.push(field)
        });

        setInputOutputOptions(options);
    }

    
    /**
     * Add new configuration function to add a new configuration to the database and save it to the state if it's not already saved 
     *
     */
    const addNewConfiguration = async () => {
        setIsSaving(true);
        let currentUnitConfigState = {
            ...unitConfig,
            industry: unitConfig?.industry,
            subIndustry: unitConfig?.subIndustry,
            application: unitConfig?.application,
            subApplication: unitConfig?.subApplication,
            alfaLavalPosition: unitConfig?.alfaLavalPosition,
            productLine: unitConfig?.productLine,
            processStage: unitConfig?.processStage,

            updatedProcessStage: unitConfig?.processStage,
            updatedIndustry: unitConfig?.industry,
            updatedSubIndustry: unitConfig?.subIndustry,
            updatedApplication: unitConfig?.application,
            updatedSubApplication: unitConfig?.subApplication,
            updatedAlfaLavalPosition: unitConfig?.alfaLavalPosition,
            updatedProductLine: unitConfig?.productLine,
            basicDetailsUpdated: unitConfig?.basicDetailsUpdated
        }

        for (let i = 0; i < sectionCollection.length; i++) {
            let currentSection = sectionCollection[i];
            currentSection.isMultiSection = sectionCollection.length > 1 ? currentSection.isMultiSection = true : false;
            currentSection.sectionCount = sectionCollection.length;

            let payload = {
                ...unitConfig,
                ...currentSection,
                properties: unitConfig?.properties,
                modelApiKeyName: unitConfig?.modelApiKeyName,
                modelEndpointUrl: unitConfig?.modelEndpointUrl,

                industry: unitConfig?.oldIndustry === undefined ? unitConfig?.industry : unitConfig?.oldIndustry,
                subIndustry: unitConfig?.oldSubIndustry === undefined ? unitConfig?.subIndustry : unitConfig?.oldSubIndustry,
                application: unitConfig?.oldApplication === undefined ? unitConfig?.application : unitConfig?.oldApplication,
                subApplication: unitConfig?.oldSubApplication === undefined ? unitConfig?.subApplication : unitConfig?.oldSubApplication,
                productLine: unitConfig?.oldProductLine === undefined ? unitConfig?.productLine : unitConfig?.oldProductLine,
                alfaLavalPosition: unitConfig?.oldAlfaLavalPosition === undefined ? unitConfig?.alfaLavalPosition : unitConfig?.oldAlfaLavalPosition,
                processStage: unitConfig?.oldProcessStage === undefined ? unitConfig?.processStage : unitConfig?.oldProcessStage,

                updatedIndustry: unitConfig?.industry,
                updatedSubIndustry: unitConfig?.subIndustry,
                updatedApplication: unitConfig?.application,
                updatedSubApplication: unitConfig?.subApplication,
                updatedAlfaLavalPosition: unitConfig?.alfaLavalPosition,
                updatedProductLine: unitConfig?.productLine,
                updatedProcessStage: unitConfig?.processStage,

                basicDetailsUpdated: unitConfig?.basicDetailsUpdated
            }


            if (!isEdit && !isNewCreated) {
                const response = await apiService.postAsync(payload, `settings/unitconfigurations`);
                if (!response.ok) {
                    setIsSaving(false);
                    const res = await response.text();
                    throw new Error(`Couldn't save. ${res}`)
                }
                setIsNewCreated(true);
            }
            else {
                const response = await apiService.putAsync(payload, `settings/unitconfigurations`);
                if (!response.ok) {
                    setIsSaving(false);
                    const res = await response.text();
                    throw new Error(`Couldn't save. ${res}`)
                }
            }
        }

        if (sectionCollection.length === 1) {

            let current = userInputs ?? [];
            if (current.findIndex(x => x === "UnitOperationTargetValue") === -1 && assignOperationTargetValue === true) {
                current.push("UnitOperationTargetValue");
            }
            else if (current.findIndex(x => x === "UnitOperationTargetValue") > -1 && assignOperationTargetValue === false) {
                current.splice(current.findIndex(x => x === "UnitOperationTargetValue"), 1);
            }

            let payload: any = {
                ...unitConfig,
                userInputProperties: current
            }
            let response;
            if (propSpecId) {
                payload.id = propSpecId;
                response = await apiService.putAsync(payload, `settings/unitPropertySpecifications/${propSpecId}`);
            }
            else {
                response = await apiService.postAsync(payload, "settings/unitPropertySpecifications");

                //Update propSpecId in state to avoid duplicate creations later
                const result = await response.json();
                setPropSpecId(result.id);
            }

            if (!response.ok) {
                const res = await response.text();
                throw new Error(`Couldn't save. ${res}`)
            }

        }

        setIsSaving(false);
        if (unitConfig?.basicDetailsUpdated) {
            unitConfig.industry = currentUnitConfigState.updatedIndustry;
            unitConfig.subIndustry = currentUnitConfigState.updatedSubIndustry;
            unitConfig.application = currentUnitConfigState.updatedApplication;
            unitConfig.subApplication = currentUnitConfigState.updatedSubApplication;
            unitConfig.alfaLavalPosition = currentUnitConfigState.updatedAlfaLavalPosition;
            unitConfig.productLine = currentUnitConfigState.updatedProductLine;
        }
        setUnitConfig(unitConfig)
        setTemporaryState("✅ Saved", setSaveMessage);
        await getSectionsAsync();
    }

    
    /**
     * Handle base change function to handle the change of the base values for the unit configuration 
     *
     * @param {string} propertyName
     * @param {(string | boolean)} value
     */
    const handleBaseChange = (propertyName: string, value: string | boolean) => {
        let current = unitConfig;
        if (current === undefined) current = {} as IUnitBaseConfiguration;

        value = value.toString().replace('/', '');
        if (originalBasicDetails != undefined) {
            switch (propertyName) {
                case PropertyConstants.Industry:
                    current.oldIndustry = originalBasicDetails.industry;
                    current.basicDetailsUpdated = true;
                    break;
                case PropertyConstants.SubIndustry:
                    current.oldSubIndustry = originalBasicDetails.subIndustry;
                    current.basicDetailsUpdated = true;
                    break;
                case PropertyConstants.Application:
                    current.oldApplication = originalBasicDetails.application;
                    current.basicDetailsUpdated = true;
                    break;
                case PropertyConstants.SubApplication:
                    current.oldSubApplication = originalBasicDetails.subApplication;
                    current.basicDetailsUpdated = true;
                    break;
                case PropertyConstants.ProductLine:
                    current.oldProductLine = originalBasicDetails.productLine;
                    current.basicDetailsUpdated = true;
                    break;
                case PropertyConstants.AlfaLavalPosition:
                    current.oldAlfaLavalPosition = originalBasicDetails.alfaLavalPosition;
                    current.basicDetailsUpdated = true;
                    break;
                case PropertyConstants.ProcessStage:
                    current.oldProcessStage = originalBasicDetails.processStage;
                    current.basicDetailsUpdated = true;
                    break;
            }
        }
        setUnitConfig({ ...current, [propertyName]: value });
    }

    
    /**
     * Handle multi section change function to handle the change of the values for the multi section unit configuration 
     *
     * @param {string} property
     * @param {*} e
     * @param {number} index
     */
    const handleMultiSectionChange = (property: string, e: any, index: number) => {
        let currentSelectableProperties = flowchartSelectableProperties;
        let targetIndex = sectionCollection.findIndex(x => x.sectionNumber === index);
        let target = sectionCollection[targetIndex];
        if (target === undefined) target = { sectionNumber: index }

        const sectionPropertyName = `${e.key}Sec${index}`
        if (property === PropertyConstants.Inputs) {
            let currentInputs = target.inputs ?? [];
            if (e.selected === true) {
                currentInputs.push(e.key);
                target = { ...target, [property]: currentInputs };
                currentSelectableProperties.push(sectionPropertyName);
            }
            else {
                let index = currentInputs.indexOf(e.key);
                currentInputs.splice(index, 1);
                target = { ...target, [property]: currentInputs };
                currentSelectableProperties.splice(currentSelectableProperties.indexOf(sectionPropertyName), 1);
            }
        }
        else if (property === PropertyConstants.Outputs) {
            let currentOutputs = target.outputs ?? [];
            if (e.selected === true) {
                currentOutputs.push(e.key);
                target = { ...target, [property]: currentOutputs };
                currentSelectableProperties.push(sectionPropertyName);
            }
            else {
                let index = currentOutputs.indexOf(e.key);
                currentOutputs.splice(index, 1);
                target = { ...target, [property]: currentOutputs };
                currentSelectableProperties.splice(currentSelectableProperties.indexOf(sectionPropertyName), 1);
            }
        }
        else {
            target = { ...target, [property]: e };
        }


        let updatedCollection = [...sectionCollection];
        updatedCollection[targetIndex] = target;
        setSectionCollection(updatedCollection);
        setFlowchartSelectableProperties(currentSelectableProperties);
    }

    
    /**
     * Handle set section count function to handle the setting of the section count for the unit configuration 
     *
     * @param {number} count
     */
    const handleSetSectionCount = (count: number) => {
        setAmountOfSections(count);

        let currentSectionCollection = [];

        for (let i = count; i > 0; i--) {
            if (sectionCollection.findIndex(x => x.sectionNumber === i) > -1) {
                currentSectionCollection.push(sectionCollection.find(x => x.sectionNumber === i)!);
                continue;
            }
            currentSectionCollection.push({ sectionNumber: i, inputs: [], outputs: [], coldMediaType: "", hotMediaType: "" });
        }
        setSectionCollection(currentSectionCollection);
    }
    const disableSlashKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const disabledKeys = ['/'];
        if (disabledKeys.includes(event.key)) {
            event.preventDefault();
        }
    }

    
    /**
     * Get section buttons function to get the buttons for the sections
     *
     * @param {number} sections
     * @return {*} 
     */
    const getSectionButtons = (sections: number) => {
        let buttons = [];
        for (let i = 0; i < sections; i++) {

            buttons.push(
                i + 1 === amountOfSections ?
                    <PrimaryButton text={(i + 1).toString()} style={{ marginRight: 5 }} onClick={() => handleSetSectionCount(i + 1)} /> :
                    <DefaultButton text={(i + 1).toString()} style={{ marginRight: 5 }} onClick={() => handleSetSectionCount(i + 1)} />
            )
        }
        return buttons;
    }
    
    /**
     * Go to property specification editor function to navigate to the property specification editor page 
     *
     */
    function goToPropertySpecificationEditor(): void {
        navigate(`/admin/unitconfigurationmanagement/propertyspecificationeditor/${unitConfig?.industry}/${unitConfig?.subIndustry}/${unitConfig?.application}/${unitConfig?.subApplication}/${unitConfig?.processStage}/${unitConfig?.productLine}/${unitConfig?.alfaLavalPosition}/${sectionCollection.length}`, { state: { unitConfiguration: unitConfig } });
    }

    
    /**
     * Go to property range specification editor function to navigate to the property range specification editor page 
     *
     */
    function goToPropertyRangeSpecificationEditor(): void {
        let inputs = sectionCollection.find(c => c.sectionNumber == 1)?.inputs;
        inputs = inputs === undefined ? [] : inputs;
        inputs.push(PropertyConstants.TempApproachHotInlet);
        inputs.push(PropertyConstants.TempApproachHotOutlet);
        inputs.push(PropertyConstants.DeltaTemperatureColdSide);
        inputs.push(PropertyConstants.DeltaTemperatureHotSide);
        navigate(`/admin/unitconfigurationmanagement/propertyrangespecificationeditor/${unitConfig?.industry}/${unitConfig?.subIndustry}/${unitConfig?.application}/${unitConfig?.subApplication}/${unitConfig?.processStage}/${unitConfig?.productLine}/${unitConfig?.alfaLavalPosition}/${sectionCollection.length}/${inputs}`, { state: { unitConfiguration: unitConfig } })
    }

    return (
        <Stack>
            <Stack horizontal style={{ padding: 8 }}>
                <Stack.Item grow>
                    <DefaultButton iconProps={{ iconName: 'Back' }} text="Back" onClick={() => navigate("/admin")} />
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal>
                        <Stack.Item style={{ marginTop: 5, marginRight: 8 }}>
                            {
                                isSaving && <Spinner labelPosition="left" label="Saving..." size={SpinnerSize.small} />
                            }
                            {
                                saveMessage &&
                                <span>{saveMessage}</span>
                            }
                        </Stack.Item>
                        <PrimaryButton iconProps={{ iconName: 'Save' }} text="Save" onClick={() => addNewConfiguration()} disabled={isSaving} />
                        <PrimaryButton
                            text="Property specification"
                            style={{ marginLeft: 8 }}
                            iconProps={{ iconName: 'Equalizer' }}
                            onClick={() => goToPropertySpecificationEditor()}
                        />
                        <PrimaryButton
                            text="Property range specification"
                            style={{ marginLeft: 8 }}
                            iconProps={{ iconName: 'info' }}
                            disabled={isSaving || (!isNewCreated && !isEdit)}
                            onClick={() => goToPropertyRangeSpecificationEditor()}
                        />
                    </Stack>
                </Stack.Item>
            </Stack>
            {
                isLoading ?
                    <Spinner label="Loading..." />
                    :
                    <>
                        {
                            !isEdit &&
                            <Stack.Item style={{ paddingLeft: 40 }}>
                                <Label>Sections</Label>
                                {
                                    getSectionButtons(5)
                                }

                            </Stack.Item>
                        }

                        <Stack horizontalAlign="center">
                            <Stack.Item grow style={{ padding: 5, marginLeft: 10 }}>
                                <h3>Unit configuration</h3>
                            </Stack.Item>
                            <Stack horizontal>
                                <Stack grow>
                                    <Stack style={{ padding: 20 }} tokens={{ childrenGap: 8 }}>
                                        <Stack styles={generalStackStyles(theme)}>
                                            <Stack horizontal tokens={{ childrenGap: 8 }}>
                                                <Stack.Item grow>
                                                    <Label>Industry</Label>
                                                    <TextField style={{ height: 30 }} value={unitConfig?.industry ?? ""} onKeyPress={disableSlashKey} onChange={(e) => handleBaseChange(PropertyConstants.Industry, e.currentTarget.value)} />
                                                </Stack.Item>
                                                <Stack.Item grow>
                                                    <Label>SubIndustry</Label>
                                                    <TextField value={unitConfig?.subIndustry ?? ""} onKeyPress={disableSlashKey} onChange={(e) => handleBaseChange(PropertyConstants.SubIndustry, e.currentTarget.value)} />
                                                </Stack.Item>
                                                <Stack.Item grow>
                                                    <Label>Application</Label>
                                                    <TextField value={unitConfig?.application ?? ""} onKeyPress={disableSlashKey} onChange={(e) => handleBaseChange(PropertyConstants.Application, e.currentTarget.value)} />
                                                </Stack.Item>
                                                <Stack.Item grow>
                                                    <Label>Subapplication</Label>
                                                    <TextField value={unitConfig?.subApplication ?? ""} onKeyPress={disableSlashKey} onChange={(e) => handleBaseChange(PropertyConstants.SubApplication, e.currentTarget.value)} />
                                                </Stack.Item>
                                            </Stack>

                                            <Stack horizontal tokens={{ childrenGap: 8 }}>
                                                <Stack.Item grow>
                                                    <Label>Process stage</Label>
                                                    <TextField value={unitConfig?.processStage ?? ""} onKeyPress={disableSlashKey} onChange={(e) => handleBaseChange(PropertyConstants.ProcessStage, e.currentTarget.value)} />
                                                </Stack.Item>
                                                <Stack.Item grow>
                                                    <Label>Product line</Label>
                                                    <Dropdown selectedKey={unitConfig?.productLine} options={[{ key: 'Hygienic', text: 'Hygienic' }, { key: 'Industrial', text: 'Industrial' }]}
                                                        onChange={(e, item) => handleBaseChange(PropertyConstants.ProductLine, item!.key.toString())} />
                                                </Stack.Item>
                                                <Stack.Item grow>
                                                    <Label>Alfa laval position</Label>
                                                    <TextField value={unitConfig?.alfaLavalPosition ?? ""} onKeyPress={disableSlashKey} onChange={(e) => handleBaseChange(PropertyConstants.AlfaLavalPosition, e.currentTarget.value)} />
                                                </Stack.Item>
                                            </Stack>

                                            <Stack>
                                            </Stack>

                                            <Stack horizontal style={{ marginTop: 10 }} tokens={{ childrenGap: 8 }} >
                                                <Stack.Item grow>
                                                    <Label>Flow type</Label>
                                                    <ComboBox options={[
                                                        { key: "mass", text: `Mass (${getPreferredUnits().preferredUnits === "us" ? "lb/h" : "kg/h"})` },
                                                        { key: "volumetric", text: `Volumetric (${getPreferredUnits().preferredUnits === "us" ? "GPM" : "m3/h"})` },
                                                    ]}
                                                        defaultSelectedKey={unitConfig !== undefined && unitConfig.flowType !== undefined ? deCapitalizeFirstLetter(unitConfig?.flowType!) : ""}
                                                        onChange={(e, item) => handleBaseChange(PropertyConstants.FlowType, item?.key.toString() ?? "")} />
                                                </Stack.Item>
                                                <Stack.Item style={{ marginLeft: 20 }} grow>
                                                    <Label>Allowed PHE Types</Label>
                                                    <ComboBox
                                                        id="PHEtype"
                                                        multiSelect
                                                        options={pheTypesOptions!}
                                                        onChange={(e, i) => handleComboBoxChange(i, PropertyConstants.PHEType)}
                                                        defaultSelectedKey={unitConfig?.pheTypes?.map((x) => { return x.split(',')[0] })}
                                                    />
                                                </Stack.Item>
                                                <Stack.Item grow>
                                                    <Label>Operation target name</Label>
                                                    <TextField disabled={isEdit} onKeyPress={disableSlashKey} onChange={(e) => handleBaseChange(PropertyConstants.UnitOperationTargetName, e.currentTarget.value,)} value={unitConfig?.unitOperationTargetName ?? ""} />
                                                </Stack.Item>
                                                <Stack.Item grow>
                                                    <Label>Operation target unit</Label>
                                                    <TextField disabled={isEdit} onKeyPress={disableSlashKey} onChange={(e) => handleBaseChange(PropertyConstants.UnitOperationTargetValueUnit, e.currentTarget.value)} value={unitConfig?.unitOperationTargetValueUnit ?? ""} />
                                                </Stack.Item>
                                                {
                                                    sectionCollection.length === 1 &&
                                                    <Stack.Item grow>
                                                        <Label>Assign operation target value</Label>
                                                        <Checkbox checked={assignOperationTargetValue ?? false} onChange={(e, checked) => setAssignOperationValue(checked ?? false)} />
                                                    </Stack.Item>
                                                }
                                            </Stack>
                                            <Stack horizontal style={{ marginTop: 10 }} tokens={{ childrenGap: 8 }} >
                                                <Stack.Item grow>
                                                    <Label>Model endpoint URL</Label>
                                                    <TextField onChange={(e) => handleBaseChange(PropertyConstants.ModelEndpointUrl, e.currentTarget.value)} value={unitConfig?.modelEndpointUrl ?? ""} />
                                                </Stack.Item>
                                                <Stack.Item grow>
                                                    <Label>Model api key name</Label>
                                                    <TextField onKeyPress={disableSlashKey} onChange={(e) => handleBaseChange(PropertyConstants.ModelApiKeyName, e.currentTarget.value)} value={unitConfig?.modelApiKeyName ?? ""} />
                                                </Stack.Item>
                                            </Stack>
                                            <Stack horizontal tokens={{ childrenGap: 20 }}>
                                            </Stack>
                                        </Stack>

                                        {
                                            sectionCollection && !isLoading &&
                                            <Stack>
                                                <Stack horizontal>
                                                    {
                                                        sectionCollection.sort((a, b) => b.sectionNumber! - a.sectionNumber!).map((section, index) => {
                                                            return (
                                                                <Stack.Item grow key={`sectionconfig-${index}`}>
                                                                    <MultiSectionUnitConfiguration
                                                                        displayIndex={section.sectionNumber!}
                                                                        section={section}
                                                                        inputOutputOptions={inputOutputOptions}
                                                                        onChange={(property, value, i) => handleMultiSectionChange(property, value, i)}
                                                                        onClick={(e) => setSectionActive(e)}
                                                                        index={section.sectionNumber!} />
                                                                </Stack.Item>
                                                            )
                                                        })
                                                    }
                                                </Stack>
                                                {
                                                    sectionCollection.length > 1 && flowchartSelectableProperties.length > 0 &&
                                                    <Stack horizontal horizontalAlign="center" onClick={() => setActiveSection(-1)}>
                                                        <MultiSectionFlowchart
                                                            unitInputConfiguration={unitConfig!}
                                                            isEdit={isEdit}
                                                            isNewCreated={isNewCreated}
                                                            sectionCount={sectionCollection.length}
                                                            selectableProperties={flowchartSelectableProperties}
                                                            scaling={1} />
                                                        {/* {
                                                            sectionCollection.sort((a, b) => b.sectionNumber! - a.sectionNumber!).map((section, index) => {
                                                                return (
                                                                    <>
                                                                        <div key={`sectionconfig-${index}`}>
                                                                            <MultiSectionVisualization
                                                                                isDrawingMode={true}
                                                                                label={section.sectionNumber?.toString()}
                                                                                isActive={activeSection === section.sectionNumber}
                                                                                leftEnding={section.sectionNumber === sectionCollection.length}
                                                                                rightEnding={section.sectionNumber === 1} />
                                                                        </div>
                                                                    </>
                                                                )
                                                            })
                                                        } */}
                                                    </Stack>

                                                }
                                            </Stack>
                                        }
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    </>

            }
        </Stack>
    )
}